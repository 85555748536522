import style from "./sass/main.scss";
import "./js/galleryModal.js";

require("./404.html");

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

import MicroModal from "micromodal";
MicroModal.init({
  onShow: modal => {
    const targetElement = document.querySelector(modal.id);
    disableBodyScroll(targetElement);
    document.body.style.top = `-${window.scrollY}px`;
  },
  onClose: modal => {
    const targetElement = document.querySelector(modal.id);
    enableBodyScroll(targetElement);
    const scrollY = document.body.style.top;
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
});

function onClickCloseModal(modalId) {
  MicroModal.close(modalId);
  return true;
}

function openAdditional(modalId) {
  MicroModal.close(modalId);
  document.getElementById("additionalServiceButton").click();
  return true;
}

window.onClickCloseModal = onClickCloseModal;
window.openAdditional = openAdditional;
