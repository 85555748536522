import Hammer from "hammerjs";

const imageModal = document.getElementsByClassName("modal-main");
const mc = new Hammer(imageModal[0]);
mc.on("swiperight", function(event) {
  let now = new Date().getTime();
  if (now - lastClickTime > 500) {
    slideIndex -= 1;
    showSlides();
    lastClickTime = now;
  }
});
mc.on("swipeleft", function() {
  let now = new Date().getTime();
  if (now - lastClickTime > 500) {
    slideIndex += 1;
    showSlides();
    lastClickTime = now;
  }
});

const images = document.getElementsByClassName("gallery__item__image");
for (let i = 0; i < images.length; i++) {
  images[i].addEventListener("click", () => {
    document.getElementById("galleryImageModal").style.display = "block";
    if (slideIndex === i) {
      return;
    }
    slideIndex = i;
    showSlides();
  });
}

const demos = document.getElementsByClassName("demo");
for (let i = 0; i < demos.length; i++) {
  demos[i].addEventListener("click", () => {
    if (slideIndex === i) {
      return;
    }
    slideIndex = i;
    showSlides();
  });
}

document
  .getElementById("gallery__modal__close")
  .addEventListener("click", () => {
    document.getElementById("galleryImageModal").style.display = "none";
  });

document
  .getElementById("gallery__modal__prev")
  .addEventListener("click", () => {
    let now = new Date().getTime();
    if (now - lastClickTime > 500) {
      slideIndex -= 1;
      showSlides();
      lastClickTime = now;
    }
  });

document
  .getElementById("gallery__modal__next")
  .addEventListener("click", () => {
    let now = new Date().getTime();
    if (now - lastClickTime > 500) {
      slideIndex += 1;
      showSlides();
      lastClickTime = now;
    }
  });

let slideIndex = -1;
let lastClickTime = 0;

function showSlides() {
  const slides = document.getElementsByClassName("gallerySlides");
  // Simple maths for wrap around index
  if (slideIndex >= slides.length) {
    slideIndex = 0;
  } else if (slideIndex < 0) {
    slideIndex = slides.length - 1;
  }
  const currentSlide = slides[slideIndex];
  if (!currentSlide.classList.contains("hidden")) {
    // This should never happen
    return;
  }

  const dots = document.getElementsByClassName("demo");
  const captionText = document.getElementById("caption");
  const imageCount = document.getElementById("imageCount");

  // Edit appearance of thumbnails
  for (let i = 0; i < dots.length; i++) {
    dots[i].classList.remove("active");
  }
  dots[slideIndex].classList.add("active");

  // Update image information
  captionText.innerHTML = dots[slideIndex].alt;
  imageCount.innerHTML = slideIndex + 1 + " / " + slides.length;

  // Hide old slides
  // Previous slide is determined by the one that doesn't contain 'hidden'
  let previousSlide = Array.from(slides).filter(slide => {
    return !slide.classList.contains("hidden");
  });
  if (previousSlide.length == 0) {
    // There were no previous slides so irst time displaying slide
    currentSlide.classList.remove("hidden");
    setTimeout(function() {
      currentSlide.classList.remove("visually-hidden");
    }, 20);
    return;
  }
  previousSlide = previousSlide[0];
  previousSlide.classList.add("visually-hidden");
  previousSlide.classList.add("hidden");

  // Show current slide
  currentSlide.classList.remove("hidden");
  setTimeout(function() {
    currentSlide.classList.remove("visually-hidden");
  }, 20);
}
